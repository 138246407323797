@charset "utf-8";
.m-sideNavi {
  position: absolute;
  width: 140px;
  top: 0;
  right: 0;
  @media (max-height: 800px) {
    top: -3vh;
  }
  &.abso {
    position: absolute;
    top: auto;
    bottom: 0;
    transform: none;
    .m-sideNavi__inner {
      position: relative;
    }
  }
  @include rp {
    display: none;
  }
  @include sp {
    display: none;
  }
  &__inner {
    position: fixed;
  }
  &__ttl {
    position: relative;
    font-size: 20px;
    padding-left: 25px;
    @media (max-height: 800px) {
      font-size: 2.5vh;
      padding-left: 3vh;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      background: url(/wp-content/themes/stanley/assets/img/sideNavi/search.svg);
      background-position: center left;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
      @media (max-height: 800px) {
        width: 2vh;
        height: 2vh;
      }
    }
  }
  &__list {
    margin-top: 10px;
    @media (max-height: 800px) {
      margin-top: 1.0vh;
    }
  }
  &__listItem {
    font-size: 14px;
    background: #FFF;
    @media (max-height: 800px) {
      font-size: 1.8vh;
    }
    &:nth-of-type(n+2) {
      margin-top: 2px;
      @media (max-height: 800px) {
        margin-top: 0.2vh;
      }
    }
    a {
      position: relative;
      display: flex;
      align-items: center;
      width: 140px;
      height: 36px;
      padding: 0 10px;
      @media (max-height: 800px) {
        height: 4vh;
      }
      &:after {
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        right: 10px;
        width: 4px;
        height: 8px;
        background: url(/wp-content/themes/stanley/assets/img/sideNavi/arrow.svg);
        background-position: center right;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateY(-50%);
      }
    }
  }
}