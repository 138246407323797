@charset "utf-8";
.p-top {
  //lineup
  &__lineup {
    
  }
  &__lineupList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 980px;
    margin: 25px auto 0;
    @include rp {
      margin: 30/768*100vw 26/768*100vw;
      // width:650/780*100vw;
    }
    @include sp {
      width: 100%;
      margin: 40/750*100vw auto 0;
    }
  }
  &__lineupListItem {
    background: #FFF;
    @include pc {
      width: 316px;
      &:nth-of-type(n+4) {
        margin-top: 40px;
      }
    }
    @include rp {
      width: 350/768*100vw;
      max-width: 481px;
      &:nth-of-type(n+3) {
        margin-top: 20px;
      }
    }
    @include sp {
      width: 327/750*100vw;
      &:nth-of-type(n+3) {
        margin-top: 40/750*100vw;
      }
    }
    a {
      display: block;
    }
  }
  &__lineupListImg {
    
  }
  &__lineupListTxt {
    padding: 20px 15px;
    @include sp {
      padding: 20/750*100vw 25/750*100vw;
    }
    .en {
      display: block;
      font-size: 12px;
      @include sp {
        @include vw(18);
      }
    }
    .ja {
      display: block;
      font-size: 16px;
      @include sp {
        @include vw(26);
      }
    }
  }
  
  //news
  &__news {
    position: relative;
    width: 980px;
    margin: 100px auto 0;
    @include rp {
      margin: 30/768*100vw 26/768*100vw;
      width: auto;
    }
    @include sp {
      width: 100%;
      margin: 120/750*100vw auto 0;
    }
  }
  &__newsTtl {
    font-size: 30px;
    @include sp {
      @include vw(42);
    }
    span {
      font-size: 14px;
      @include sp {
        @include vw(24);
      }
    }
  }
  &__newsList {
    margin-top: 20px;
    @include sp {
      margin-top: 30/750*100vw;
    }
  }
  &__newsListItem {
    padding: 20px 0;
    border-top: 1px solid #D6D6D6;
    @include pc {
      display: flex;
      align-items: center;
    }
    @include sp {
      padding: 30/750*100vw 0;
    }
    &:last-of-type {
      border-bottom: 1px solid #D6D6D6;
    }
    .day {
      color: #EB5C01;
      font-size: 16px;
      @include pc {
        margin-right: 40px;
      }
      @include sp {
        display: block;
        @include vw(26);
      }
    }
    .txt {
      font-size: 16px;
      @include sp {
        display: block;
        @include vw(26);
        margin-top: 5/750*100vw;
      }
    }
  }
  &__newsLink {
    font-size: 14px;
    @include pc {
      position: absolute;
      top: 15px;
      right: 0;
    }
    @include sp {
      width: 400/750*100vw;
      margin: 80/750*100vw auto 0;
    }
    a {
      @include sp {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100/750*100vw;
        @include vw(26);
        border: 2/750*100vw solid #707070;
      }
    }
  }
}