.m-btn {
  width: 426px;
  margin: 80px auto 0;
  @include sp {
    width: 670/750*100vw;
    margin: 100/750*100vw auto 0;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 68px;
    font-size: 14px;
    background: #f2f1f0;
    border: 1px solid #525252;
    transition: all 0.3s;
    @include pc {
      &:hover {
        color: #FFF;
        text-decoration: none;
        background: #EB5C01;
      }
    }
    @include sp {
      height: 140/750*100vw;
      @include vw(26);
      &:hover {
        text-decoration: none;
      }
    }
  }
}