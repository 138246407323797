@charset "utf-8";
.m-ttl {
  font-size: 30px;
  @include rp {
    margin-left: 26/768*100vw;
  }
  @include sp {
    @include vw(42);
  }
  span {
    font-size: 14px;
    @include sp {
      @include vw(24);
    }
  }
}