.p-conformity {
  &__ttl {
    padding-bottom: 20px;
    border-bottom: 1px solid #D6D6D6;
    @include rp {
      margin: 0 26/768*100vw;
    }
    @include sp {
      padding-bottom: 30/750*100vw;
    }
  }
  &__subttl {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.8;
    margin-top: 40px;
    @include rp {
      margin: 20/768*100vw 26/768*100vw;
    }
    @include sp {
      @include vw(30);
      margin-top: 50/750*100vw;
    }
  }
  &__list {
    font-size: 14px;
    line-height: 1.8;
    margin-top: 40px;
    @include rp {
      margin: 20/768*100vw 26/768*100vw;
    }
    @include sp {
      @include vw(26);
      margin-top: 50/750*100vw;
    }
  }
  &__listItem {
    position: relative;
    padding-left: 2.2em;
    &:nth-of-type(n+2) {
      margin-top: 10px;
      @include sp {
        margin-top: 3vw;
      }
    }
    &:before {
      position: absolute;
      display: block;
      top: 0px;
      left: 0;
    }
    &:nth-of-type(1) {
      &:before {
        content: '（1）';
      }
    }
    &:nth-of-type(2) {
      &:before {
        content: '（2）';
      }
    }
    &:nth-of-type(3) {
      &:before {
        content: '（3）';
      }
    }
    &:nth-of-type(4) {
      &:before {
        content: '（4）';
      }
    }
    &:nth-of-type(5) {
      &:before {
        content: '（5）';
      }
    }
    &:nth-of-type(6) {
      &:before {
        content: '（6）';
      }
    }
    &:nth-of-type(7) {
      &:before {
        content: '（7）';
      }
    }
    &:nth-of-type(8) {
      &:before {
        content: '（8）';
      }
    }
    &:nth-of-type(9) {
      &:before {
        content: '（9）';
      }
    }
    &:nth-of-type(10) {
      &:before {
        content: '（10）';
      }
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 870px;
    @include rp {
      display: block;
      width: 100%;
    }
    @include sp {
      display: block;
      width: 100%;
      margin-top: 65/750*100vw;
    }
    a {
      width: 426px;
      @include rp {
        margin: 20/768*100vw auto;
        &:nth-of-type(1) {
          margin-top: 40/768*100vw;
        }
        &:nth-of-type(2) {
          margin-bottom: 0;
        }
      }
      @include sp {
        width: 100%;
      }
      &:nth-of-type(1) {
        color: #FFF;
        background: #EB5C01;
        @include pc {
          &:hover {
            color: #000;
            background: #FFF;
            border: 1px solid #525252;
          }
        }
      }
      &:nth-of-type(2) {
        @include sp {
          margin-top: 40/750*100vw;
        }
      }
    }
  }
}