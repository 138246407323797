@charset "utf-8";
.p-newsDetails {
  &__ttl {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #D6D6D6;
    @include rp {
      margin: 20/768*100vw 26/768*100vw;
    }
  }
  &__ttlMain {
    max-width: 830px;
    font-size: 30px;
    @include sp {
      max-width: 100%;
      @include vw(42);
    }
  }
  &__ttlDay {
    color: #EB5C01;
    font-size: 26px;
    @include sp {
      position: absolute;
      bottom: -9vw;
      left: 0;
      @include vw(26);
    }
  }
  
  &__block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;
    text-align: left;
    margin-right: auto;
    @include rp {
      display: block;
      margin: 20/768*100vw 26/768*100vw;
    }
    @include sp {
      display: block;
      margin-top: 95/750*100vw;
    }
    img {
      height: auto;
    }
  }
  &__box {
    width: 480px;
    @include sp {
      width: 100%;
      margin-top: 50/750*100vw;
    }
  }
  &__boxTxt {
    font-size: 16px;
    line-height: 1.8;
    @include sp {
      @include vw(28);
    }
  }
  &__boxArea {
    margin-top: 20px;
    @include sp {
      margin-top: 50/750*100vw;
    }
  }
  &__boxImg {
    width: 566px;
    @include rp {
      margin:0 auto 20/768*100vw;
    }
    @include sp {
      width: 100%;
    }
    img {
      height: auto;
    }
  }
}