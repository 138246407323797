@charset "utf-8";
.p-productsUnder {
  &__product {}
  &__productInner {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    @include rp {
      display: block;
    }
    @include sp {
      display: block;
    }
  }
  &__productBlock {
    width: 480px;
    @include rp {
      width: 100%;
      // margin: 20/768*100vw 26/768*100vw;;
    }
    @include sp {
      width: 100%;
      margin-top: 50/750*100vw;
    }
  }
  &__productNum {
    font-size: 14px;
    @include rp {
      margin: 20/768*100vw 26/768*100vw;
    }
    @include sp {
      @include vw(26);
    }
    span {
      font-size: 28px;
      @include sp {
        @include vw(42);
      }
    }
  }
  &__productType {
    font-size: 14px;
    margin-top: 10px;
    @include rp {
      margin: 20/768*100vw 26/768*100vw;
    }
    @include sp {
      @include vw(26);
    }
    span {
      font-size: 28px;
      @include sp {
        @include vw(42);
      }
    }
  }
  &__productTag {
    display: flex;
    align-items: center;
    margin-top: 30px;
    @include rp {
      flex-wrap: wrap;
      margin: 20/768*100vw 26/768*100vw;
    }
    @include sp {
      flex-wrap: wrap;
      margin-top: 30/750*100vw;
    }
  }
  &__productTagItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 32px;
    font-size: 14px;
    background: #E2E2E2;
    padding: 0 10px;
    border-radius: 2px;
    @include pc {
      &:nth-of-type(n+2) {
        margin-left: 10px;
      }
    }
    @include sp {
      height: 62/750*100vw;;
      @include vw(26);
      padding: 0 25/750*100vw;
      &:nth-of-type(2) {
        margin-left: 15/750*100vw;
      }
      &:nth-of-type(3) {
        margin-top: 15/750*100vw;
      }
    }
  }
  &__productDetails {
    margin-top: 25px;
    @include rp {
      margin: 20/768*100vw 26/768*100vw;
    }
    @include sp {
      margin-top: 40/750*100vw;
    }
  }
  &__productDetailsItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    border-top: 1px solid #D6D6D6;
    @include sp {
      @include vw(26);
      padding: 15/750*100vw 0;
    }
    &:last-of-type {
      border-bottom: 1px solid #D6D6D6;
    }
    span {
      width: 240px;
      @include sp {
        width: 335/750*100vw;
      }
    }
  }
  &__productCode {
    font-size: 14px;
    margin-top: 30px;
    @include rp {
      margin: 20/768*100vw 26/768*100vw;
    }
    @include sp {
      @include vw(26);
      margin-top: 60/750*100vw;
    }
  }
  &__productPrice {
    font-size: 14px;
    margin-top: 10px;
    @include rp {
      margin: 20/768*100vw 26/768*100vw;
    }
    @include sp {
      @include vw(26);
      margin-top: 10/750*100vw;
    }
  }
  &__productBtn {
    margin: 60px 0 0;
    @include rp {
      margin: 85/768*100vw auto 0;
    }
    @include sp {
      margin: 100/750*100vw auto 0;
    }
  }
  &__productSlider {
    position: relative;
    width: 560px;
    @include rp {
      width: 660/768*100vw;
      margin: 0 auto;
    }
    @include sp {
      width: 640/750*100vw;
      margin: 0 auto;
    }
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 40%;
      width: 22px;
      height: 41px;
      margin-top: 0;
      z-index: 10;
      cursor: pointer;
      background-size: 22px 41px;
      background-image: none;
      background-position: center;
      background-repeat: no-repeat;
      @include rp {
        display: none;
      }
      @include sp {
        display: none;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      &:focus {
        outline: 0;
      }
    }
  }
  .swiper-container {
    &--main {
      width: 440px;
      @include rp {
        width: 100%;
      }
      @include sp {
        width: 100%;
      }
    }
    &--thumbnail {
      width: 440px;
      margin-top: 10px;
      @include rp {
        width: 100%;
        margin-top: 18/768*100vw;
      }
      @include sp {
        width: 100%;
        margin-top: 15/750*100vw;
      }
      .swiper-slide {
        position: relative;
        width: 70px !important;
        @include rp {
          width: 120/768*100vw !important;
        }
        @include sp {
          width: 110/750*100vw !important;
        }
        &:before {
          position: absolute;
          display: block;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.5);
        }
      }
      .swiper-slide-thumb-active {
        &:before {
          position: absolute;
          display: block;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0);
        }
      }
      .swiper-slide-visible {
        cursor: pointer;
      }
    }
  }
}