@charset "utf-8";
.p-search {
  @include rp {
    padding: 104/768*100vw 26/768*100vw 200/768*100vw;
  }
  @include sp {
    padding: 104/750*100vw 0 200/750*100vw;
  }
  section {
    &:nth-of-type(n+2) {
      margin-top: 80px;
      @include sp {
        margin-top: 74/750*100vw;
      }
    }
  }
  
  &__ttl {
    font-size: 30px;
    padding-bottom: 25px;
    @include sp {
      @include vw(42);
      padding-bottom: 42/750*100vw;
    }
    &--size {
      @include rp {
        padding-bottom: 10px;
      }
    }
    span {
      font-size: 13px;
      margin-left: 25px;
      @include sp {
        @include vw(23);
        margin-left: 46/750*100vw;
      }
    }
    &--border {
      margin-bottom: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid #D6D6D6;
      @include sp {
        margin-bottom: 53/750*100vw;
        padding-bottom: 42/750*100vw;
      }
    }
  }
  &__txt {
    font-size: 14px;
    @include sp {
      @include vw(26);
    }
  }
  &__subttl {
    font-size: 20px;
    margin-bottom: 25px;
    margin-top: 60px;
    @include sp {
      margin-bottom: 23/750*100vw;
      margin-top: 35/750*100vw;
    }
  }
  &__window {
    display: flex;
    align-items: center;
  }
  &__windowContents {
    width: 630px;
    height: 50px;
    font-size: 18px;
    padding: 10px;
    background: #FFF;
    @include sp {
      width: 670/750*100vw;
      height: 90/750*100vw;
    } 
  }
  &__windowBtn {
    width: 50px;
    height: 50px;
    background: #EB5C01;
    background: url(/wp-content/themes/stanley/assets/img/search/search_icon.svg)#EB5C01;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    @include sp {
      width: 90/750*100vw;
      height: 90/750*100vw;
    }
    img {
      width: 16px;
      @include sp {
        width: 31/750*100vw;
      }
    }
    &:hover {
      opacity: 0.75;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 1162px;
    margin-left: -16px;
    @include rp {
      width: 100%;
    }
    @include sp {
      justify-content: space-between;
      width: 670/750*100vw;
      margin-left: 0;
    }
    &:after {
      justify-content: space-between;
      display: block;
      content: '';
      width: 216px;
      @include sp {
        display: block;
        content: "";
        width: 210/750*100vw;
      }
    }
  }
  &__listItem {
    display: flex;
    align-items: center;
    width: 216px;
    height: 50px;
    font-size: 14px;
    // padding: 0 15px;
    margin-left: 16px;
    background: #FFF;
    cursor: pointer;
    @include rp {
        margin-top: 15px;
    }
    @include sp {
      width: 210/750*100vw;
      height: 90/750*100vw;
      margin-top: 20/750*100vw;
      margin-left: 0;
      @include vw(26);
      // padding: 0 24/750*100vw;
    }
    &:nth-of-type(n+6) {
      margin-top: 15px;
      @include sp {
        margin-top: 20/750*100vw;
      }
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 15px 0 16px 15px;
      text-decoration: none;
      @include sp {
        padding: 28/750*100vw 0 27/750*100vw 24/750*100vw;
      }
    }
  }
  &__order {
    display: flex;
    align-items: center;
    @include sp{
      align-items: left;
      flex-wrap: wrap;
    }
  }
  &__orderItem {
    color: #EB5C01;
    font-size: 18px;
    cursor: pointer;
    @include sp {
      @include vw(30);
      margin-bottom: 45/750*100vw;
    }
    &:nth-of-type(n+2) {
      margin-left: 25px;
      @include sp {
        margin-left: 1.2em;
      }
    }
    &:first-child {
      @include sp {
        margin-left: 0;
      }
    }
    &:nth-of-type(8) {
      @include sp {
        margin-left: 0;
      }
    }
  }
  &__listModel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 1162px;
    margin-left: -16px;
    @include rp {
      width: 100%;
      justify-content: space-between;
    }
    @include sp {
      width: 670/750*100vw;
      margin-left: 0;
    }
  }
  &__listItemModel {
    width: 274px;
    height: 56px;
    margin-left: 16px;
    font-size: 14px;
    // padding: 0 15px;
    background: #FFF;
    cursor: pointer;
    text-align: center;
    // padding: 17px 0;
    @include rp {
      width: 340/768*100vw;
      &:nth-of-type(n+3) {
        margin-top: 16px;
      }
    }
    @include sp {
      width: 100%;
      margin-left: 0;
    }
    &:nth-of-type(n+5) {
      margin-top: 14px;
      @include sp {
        margin-right: 0;
      }
    }
    &:nth-of-type(n+2) {
      @include sp {
        margin-top: 20/750*100vw;
      }
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 17px 0;
      text-decoration: none;
    }
  }
}