@charset "utf-8";
.p-products {
  section {
    &:nth-of-type(n+2) {
      margin-top: 80px;
      @include sp {
        margin-top: 120/750*100vw;
      }
    }
  }
  &__kv {
    @include pc {
      position: relative;
      min-width: 1200px;
      z-index: 1;
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        width: 50vw;
        height: 150px;
        z-index: -1;
      }
      &.ledbulb {
        &:before {
          background: #29c973;
        }
      }
      &.hyperbulb {
        &:before {
          background: #1b9fe9;
        }
      }
      &.headlamp {
        &:before {
          background: #eb5c00;
        }
      }
      &.compilation {
        &:before {
          background: #6a1be8;
        }
      }
      &.dooropener {
        &:before {
          background: #d24570;
        }
      }
      &.work {
        &:before {
          background: #d2b617;
        }
      }
      &:after {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        right: 0;
        width: 50vw;
        height: 150px;
        background: #000;
        z-index: -1;
      }
    }
  }
  &__kvInner {
    position: relative;
    width: 1366px;
    margin: 0 auto;
    @include rp {
      width: 100%;
      height: auto;
    }
    @include sp {
      width: 100%;
    }
  }
  &__kvImg {
    img {
      vertical-align: middle;
    }
  }
  &__kvTxt {
    position: absolute;
    top: 50%;
    left: 110px;
    color: #FFF;
    transform: translateY(-50%);
    @include rp {
      left: 40/768*100vw;
    }
    @include sp {
      left: 40/750*100vw;
    }
    .en {
      font-size: 14px;
      @include sp {
        @include vw(22);
      }
    }
    .ja {
      font-size: 32px;
      @include sp {
        @include vw(38);
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 40px;
    @include rp {
      margin: 20/768*100vw 26/768*100vw;
      justify-content: space-between;
    }
    @include sp {
      margin-top: 60/750*100vw;
      justify-content: space-between;
    }
  }
  &__list a {
    margin: 5px;
    @include sp {
      margin: 0;
    }
  }
  &__listItem {
    @include pc {
      width: 316px;
      transition: all 0.3s;
      &:nth-of-type(n+4) {
        margin-top: 60px;
      }
    }
    @include rp {
      width: 335/768*100vw;
    }
    @include sp {
      width: 327/750*100vw;
      &:nth-of-type(n+3) {
        margin-top: 80/750*100vw;
      }
    }
    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }
  &__listImg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after {
      display: block;
      content: '';
      width: 50%;
    }
    img {
      flex-shrink: 0;
      @include pc {
        width: 157px;
      }
      @include rp {
        width: 50%;
        height: 50%;
      }
      @include sp {
        width: 162/750*100vw;
      }
      &:nth-of-type(2) {
        margin-left: 2px;
        @include sp {
          margin-left: 3/750*100vw;
        }
      }
    }
  }
  &__listProductNum {
    font-size: 13px;
    margin-top: 15px;
    @include sp {
      @include vw(24);
      margin-top: 15/750*100vw;
    }
    span {
      font-size: 16px;
      vertical-align: baseline;
      @include sp {
        @include vw(32);
      }
    }
  }
  &__listType {
    font-size: 13px;
    @include sp {
      @include vw(24);
    }
    span {
      font-size: 16px;
      vertical-align: baseline;
      @include sp {
        @include vw(32);
      }
    }
  }
  &__listTag {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    @include sp {
      display: block;
      margin-top: 20/750*100vw;
    }
  }
  &__listTagItem {
    color: #525252;
    font-size: 13px;
    padding: 0 10px;
    background: #E2E2E2;
    @include pc {
      display: flex;
      align-items: center;
      height: 28px;
      margin: 5px 5px 0 0;
    }
    @include sp {
      display: inline-block;
      @include vw(24);
      line-height: 1.0;
      padding: 10/750*100vw 15/750*100vw;
      &:nth-of-type(n+2) {
        margin-top: 10/750*100vw;
      }
    }
  }
  &__listDetails {
    margin-top: 15px;
    @include sp {
      margin-top: 30/750*100vw;
    }
  }
  &__listDetailsItem {
    font-size: 13px;
    line-height: 1.8;
    @include sp {
      @include vw(24);
    }
  }
  &__listCode {
    font-size: 13px;
    line-height: 1.8;
    margin-top: 15px;
    @include sp {
      @include vw(24);
      margin-top: 20/750*100vw;
    }
  }
  &__listPrice {
    font-size: 13px;
    line-height: 1.8;
    @include sp {
      @include vw(24);
      margin-top: 20/750*100vw;
    }
  }
  
  //table
  .sp-scroll {
    @include rp {
      overflow-x: scroll;
    }
    @include sp {
      overflow-x: scroll;
    }
  }
  &__table {
    display: block;
    width: 980px;
    margin-top: 20px;
    border: none;
    border-collapse: collapse;
    @include rp {
      // width: 100%;
      margin: 20/768*100vw;
    }
    @include sp {
      margin-top: 25/750*100vw;
    }
    th {
      color: #FFF;
      font-size: 15px;
      padding: 5px 10px;
      background: #525252;
      border: 1px solid #E6E6E6;
      @include sp {
        @include vw(26);
        padding: 10/750*100vw;
      }
    }
    td {
      font-size: 15px;
      padding: 5px 10px;
      border: 1px solid #E6E6E6;
      @include sp {
        @include vw(24);
        padding: 10/750*100vw 20/750*100vw;
      }
      &.bg-grey {
        font-size: 15px;
        text-align: center;
        background: #E6E6E6;
        @include sp {
          @include vw(26);
        }
      }
    }
  }
  
  //size
  &__size {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    @include sp {
      margin-top: 25/750*100vw;
    }
  }
  &__sizeItem {
    @include pc {
      width: 480px;
      &:nth-of-type(n+3) {
        margin-top: 25px;
      }
    }
    @include rp {
      margin: 20/768*100vw auto;
      &:nth-of-type(n+2) {
        margin-top: 30/768*100vw;
      }
    }
    @include sp {
      display: block;
      width: 670/750*100vw;
      &:nth-of-type(n+2) {
        margin-top: 20/750*100vw;
      }
    }
  }
  
  &__bnr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
    @include rp {
      margin-top: 60/768*100vw;
    }
    @include sp {
      margin-top: 80/750*100vw;
    }
    &:after {
      display: block;
      content: '';
      width: 482px;
      @include sp {
        width: 100%;
      }
    }
  }
  &__bnrItem {
    @include pc {
      width: 482px;
      &:nth-of-type(n+3) {
        margin-top: 15px;
      }
    }
    @include rp {
      width: 100%;
      margin: 0 55/768*100vw;
      &:nth-of-type(n+2) {
        margin-top: 30/768*100vw;
      }
    }
    @include sp {
      width: 100%;
      &:nth-of-type(n+2) {
        margin-top: 25/750*100vw;
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
  
}