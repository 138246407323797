@charset "utf-8";

.l-main {
  position: relative;
  padding: 70px 0 140px;
  background: #F2F1F0;
  overflow: hidden;
  font-family: "Noto Sans JP","Noto Sans","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ヒラギノ丸ゴ ProN W4","Hiragino Maru Gothic ProN","ヒラギノ丸ゴ Pro W4","Hiragino Maru Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",Sans-Serif;
  @include pc {
    // min-width: 1366px;
    width: 100%;
  }
  @include rp {
    width: 100%;
    margin: 0 auto;
  }
  @include sp {
    min-width: 100%;
    padding: 110/750*100vw 0 220/750*100vw;
  }
  &__inner {
    position: relative;
    width: 1146px;
    margin: 0 auto;
    @include pc {
      padding-right: 166px;
    }
    @include rp {
      width: 100%;
    }
    @include sp {
      width: 670/750*100vw;
    }
    &--news,
    &--newsDetails,
    &--lineupDtails,
    &--headlampUnder,
    &--search {
      width: 1146px;
      padding: 0;
      @include rp {
        width: 100%;
      }
      @include sp {
        width: 670/750*100vw;
      }
    }
    &--conformity {
      padding-right: 0;
    }
  }
  img {
    vertical-align: middle;
  }
}
