@charset "utf-8";
.p-catalog {
  &__ttl {
    font-size: 26px;
    @include rp {
      margin-left: 26/768*100vw;
    }
    @include sp {
      @include vw(36);
    }
  }
  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    @include rp {
      // margin-right: 210/768*100vw;
      padding: 26/768*100vw;
      // width:550/768*100vw;
    }
    @include sp {
      margin-top: 50/750*100vw;
    }
    &:after {
      display: block;
      content: '';
      width: 316px;
      @include rp {
        width: 220/768*100vw;
      }
      @include sp {
        width: 327/750*100vw;
      }
    }
  }
  &__blockItem {
    @include pc {
      width: 316px;
      &:nth-of-type(n+4) {
        margin-top: 60px;
      }
    }
    @include rp {
      width: 220/768*100vw;
      &:nth-of-type(n+4) {
        margin-top: 85/750*100vw;
      }
    }
    @include sp {
      width: 327/750*100vw;
      &:nth-of-type(n+3) {
        margin-top: 80/750*100vw;
      }
    }
  }
  &__blockItemImg {
    width: 100%;
  }
  &__blockItemBtn {
    width: 100%;
    margin-top: 20px;
    @include sp {
      margin-top: 30/750*100vw;
    }
    a {
      @include sp {
        height: 90/750*100vw;
      }
    }
  }
}