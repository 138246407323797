@charset "utf-8";
.p-details {
    @include rp {
        padding: 104/780*100vw 26/780*100vw 200/780*100vw;
      }
    &__ttl {
        font-size: 30px;
        padding-bottom: 25px;
        @include sp {
            @include vw(42);
            padding-bottom: 42/750*100vw;
        }
        &--border {
            margin-bottom: 25px;
            padding-bottom: 15px;
            border-bottom: 1px solid #D6D6D6;
            @include sp {
                margin-bottom: 60/750*100vw;
            }
        }
    }
    &__tablewrap {
        width: 100%;
        @include rp {
            overflow-x: scroll;
        }
        @include sp {
            overflow-x: scroll;
        }
    }
    &__table-type {
        width: 1146px;
        @include rp {
            width: 1000/768*100vw;
        }
        @include sp {
           width:2000/750*100vw;
        }
    }
    &__tr-top {
        background: #525252;
        display: flex;
        width: 100%;
    }
    &__th-txt1 {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        width: 400px;
        padding: 10px 0 10px 20px;
        border-right: 1px solid #777777;
        @include rp {
            width: 700/768*100vw;
        }
        @include sp {
            width: 1000/750*100vw;
            padding: 22/750*100vw 0 22/750*100vw 22/750*100vw;
        }
    }
    &__th-txt2 {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        width: 400px;
        padding: 10px 0 10px 20px;
        border-right: 1px solid #777777;
        @include rp {
            width: 700/768*100vw;
        }
        @include sp {
            @include vw(26);
            width: 1000/750*100vw;
            padding: 22/750*100vw 0 22/750*100vw 22/750*100vw;
        }
    }
    &__th-txt3 {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        width: 400px;
        padding: 10px 0 10px 20px;
        border-right: 1px solid #777777;
        @include rp {
            width: 700/768*100vw;
        }
        @include sp {
            @include vw(26);
            width: 1000/750*100vw;
            padding: 22/750*100vw 0 22/750*100vw 22/750*100vw;
        }
    }
    &__th-txt4 {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        width: 400px;
        padding: 10px 0 10px 20px;
        border-right: 1px solid #777777;
        @include rp {
            width: 700/768*100vw;
        }
        @include sp {
            @include vw(26);
            width: 1000/750*100vw;
            padding: 22/750*100vw 0 22/750*100vw 22/750*100vw;
        }
    }
    &__tr {
        display: flex;
        background: #FFFFFF;
        width: 100%;
        @include rp {
            width: 1000/768*100vw;
        }
        @include sp {
            width:2000/750*100vw;
        }
        &:first-child {
            border-bottom: 1px solid #E6E6E6;
        }
    }
    a {
        text-decoration: none;
    }
    &__td-txt1 {
        color: #EB5C01;
        font-size: 13px;
        font-weight: 400;
        width: 400px;
        padding: 5px 0 6px 21px;
        border-right: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
        text-decoration: underline;
        @include rp {
            width: 1000/768*100vw;
        }
        @include sp {
            @include vw(23);
            width: 1000/750*100vw;
            padding: 22/750*100vw 0 22/750*100vw 22/750*100vw;
        }
    }
    &__td-txt2 {
        font-size: 13px;
        font-weight: 400;
        width: 400px;
        padding: 5px 0 6px 21px;
        border-right: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
        @include rp {
            width: 1000/768*100vw;
        }
        @include sp {
            @include vw(23);
            width: 1000/750*100vw;
            padding: 22/750*100vw 0 22/750*100vw 22/750*100vw;
        }
    }
    &__td-txt3 {
        font-size: 13px;
        font-weight: 400;
        width: 400px;
        padding: 5px 0 6px 21px;
        border-right: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
        @include rp {
            width: 1000/768*100vw;
        }
        @include sp {
            @include vw(23);
            width: 1000/750*100vw;
            padding: 22/750*100vw 0 22/750*100vw 22/750*100vw;
        }
    }
    &__td-txt4 {
        font-size: 13px;
        font-weight: 400;
        width: 400px;
        padding: 5px 0 6px 21px;
        border-right: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
        @include rp {
            width: 1000/768*100vw;
        }
        @include sp {
            @include vw(23);
            width: 1000/750*100vw;
            padding: 22/750*100vw 0 22/750*100vw 22/750*100vw;
        }
    }
    &__data-wrap {
        margin-top: 0;
        margin-bottom: 17px;
        width: 100%;
        // display: flex;
        justify-content: space-between;
        @include sp {
            // margin-top: 102/750*100vw;
            margin-top: 0;
            margin-bottom: 61/750*100vw;
            display: block;
        }
    }
    &__data-container {
        display: flex;
        font-size: 26px;
        // margin-bottom: 25px;
        @include sp {
            @include vw(32);
            flex-wrap: wrap;
            margin-bottom: 44/750*100vw;
        }
    }
    &__year,&__data{
        margin-right: 1em;
    }
    &__year {
        @include sp {
            width: 100%;
            margin-bottom: 0.2em;
        }
    }
    &__link-container {
        width: 259px;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        vertical-align: bottom;
        font-size: 14px;
        text-decoration: underline;
        margin-left: auto;
        @include sp {
            @include vw(26);
            padding-top: 0;
            width: 472/750*100vw;
            margin-left: 0;
        }
    }
    &__tablewrap {
        width: 100%;
        @include sp {
            overflow-x: scroll;
        }
    }
    &__table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        @include sp {
            width: 1700/750*100vw;
        }
        th {
            padding: 10px 0 10px 20px;
            text-align: left;
            color: #fff;
            background: #525252;
            font-size: 15px;
            font-weight: 400;
            border-right: 1px solid #777777;
            @include sp {
                @include vw(26);
                padding: 23/750*100vw 0 23/750*100vw 23/750*100vw;
            }
        }
        &--location {
            width: calc(100% / 5 *2);
            @include sp {
               width: 420/750*100vw;
            }
        }
        &--form {
            width: 199px;
            @include sp {
                width: 220/750*100vw;
            }
        }
        &--rating {
            width: 199px;
            @include sp {
                width: 526/750*100vw;
            }
        }
        &--number {
            width: 356px;
            @include sp {
                width: 526/750*100vw;
            }
        }
        td {
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 13px;
            @include sp {
                @include vw(23);
                padding-top: 12/750*100vw;
                padding-bottom: 12/750*100vw;
            }
        }
        &--parts {
            background: #E6E6E6;
            text-align: center;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
        }
        &--item {
            padding-left: 20px;
            background: #fff;
            border-top: 1px solid #E6E6E6;
            border-right: 1px solid #E6E6E6;
            @include sp {
                @include vw(22);
                padding-left: 22/750*100vw;
            }
        }
        &--item-none {
            @include sp {
                display: none;
            }
        }
        &--borderless {
            border-top: none;
        }
    }
}