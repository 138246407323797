@charset "utf-8";
.p-news {
  //news
  position: relative;
  width: 980px;
  margin: 100px auto 0;
  @include sp {
    width: 100%;
    margin: 120/750*100vw auto 0;
  }
  &__list {
    margin-top: 20px;
    @include rp {
      margin: 20/768*100vw 26/768*100vw;
    }
    @include sp {
      margin-top: 30/750*100vw;
    }
  }
  &__listItem {
    position: relative;
    display: block;
    padding: 40px 0;
    border-top: 1px solid #D6D6D6;
    @include rp {}
    @include sp {
      padding: 60/750*100vw 0 110/750*100vw;
    }
    &:last-of-type {
      border-bottom: 1px solid #D6D6D6;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      background: url(/wp-content/themes/stanley/assets/img/news/arrow.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      top: 50%;
      right: 0;
      width: 8px;
      height: 16px;
      transform: translateY(-50%);
      @include sp {
        width: 16/750*100vw;
        height: 32/750*100vw;
      }
    }
    .day {
      display: block;
      color: #EB5C01;
      font-size: 18px;
      @include sp {
        @include vw(26);
      }
    }
    .ttl {
      display: block;
      color: #525252;
      font-size: 24px;
      margin-top: 5px;
      @include sp {
        @include vw(34);
        margin-top: 20/750*100vw;
      }
    }
    .txt {
      display: block;
      width: 900px;
      color: #525252;
      font-size: 14px;
      line-height: 1.8;
      margin-top: 10px;
      @include rp {
        // margin: 5/768*100vw 26/768*100vw;
        width: 100%;
      }
      @include sp {
        width: 600/750*100vw;;
        @include vw(26);
        margin-top: 20/750*100vw;
      }
    }
  }
}