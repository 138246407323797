@charset "utf-8";
.l-footer {
  padding: 50px 0 100px;
  @include pc {
    // min-width: 1366px;
    width: 100%;
  }
  @include rp {
    width: 100%;
  }
  @include sp {
    width: 100%;
  }
  @include sp {
    padding: 50/750*100vw 0 75/750*100vw;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    width: 1000px;
    margin: 0 auto;
    @include rp {
      flex-wrap: wrap;
      margin: 0 auto;
      width: 100%;
    }
    @include sp {
      flex-wrap: wrap;
      width: 670/750*100vw;
    }
  }
  &__innerBlock {
    &--01 {
      width: 225px;
      @include sp {
        width: 100%;
      }
    }
    &--02 {
      width: 225px;
      @include sp {
        width: 50%;
        margin-top: 50/750*100vw;
      }
    }
    &--03 {
      width: 225px;
      @include sp {
        width: 50%;
        margin-top: 50/750*100vw;
      }
    }
    &--04 {
      width: 325px;
      @include rp {
        width: 100%;
        margin: 26/768*100vw 52/768*100vw;
      }
      @include sp {
        width: 100%;
        margin-top: 50/750*100vw;
      }
    }
  }
  &__innerList {
    &:nth-of-type(n+2) {
      margin-top: 30px;
      @include rp {
        margin-top: 50/768*100vw;
      }
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
    &.flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__innerListTtl {
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    @include sp {
      @include vw(30);
      margin-bottom: 10/750*100vw;
    }
    a {
      color: #FFF;
    }
    &.youtube {
      display: block;
      width: 315px;
      @include rp {
        width: 100%;
      }
      @include sp {
        width: 100%;
      }
      a {
        position: relative;
        display: inline-block;
        padding-right: 30px;
        @include sp {
          padding-right: 50/750*100vw;
        }
        &:before {
          position: absolute;
          display: block;
          content: '';
          top: 50%;
          right: 0;
          width: 24px;
          height: 16px;
          background: url(/wp-content/themes/stanley/assets/img/header_footer/icon_youtube.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          transform: translateY(-50%);
          @include sp {
            width: 36/750*100vw;
            height: 28/750*100vw;
          }
        }
      }
    }
  }
  &__innerListItem {
    color: #FFF;
    font-size: 14px;
    line-height: 1.8;
    @include sp {
      @include vw(24);
    }
    a {
      color: #FFF;
    }
    &.w140 {
      width: 140px;
      @include sp {
        width: 50%;
      }
    }
    &.mt {
      margin-top: 15px;
      @include sp {
        margin-top: 20/750*100vw;
      }
    }
  }
  &__innerListTxt {
    color: #FFF;
    font-size: 14px;
    line-height: 1.8;
    @include sp {
      @include vw(24);
    }
    a {
      color: #FFF;
    }
  }
  &__innerListLogo {
    margin-top: 3px;
    @include sp {
      margin-top: 5/750*100vw;
    }
    &--01 {
      width: 300px;
      @include sp {
        width: 550/750*100vw;
      }
    }
    &--02 {
      width: 280px;
      @include sp {
        width: 500/750*100vw;
      }
    }
    &--03 {
      width: 160px;
      @include sp {
        width: 280/750*100vw;
      }
    }
    &--04 {
      width: 290px;
      @include sp {
        width: 550/750*100vw;
      }
    }
  }
  &__innerCopyright {
    color: #FFF;
    font-size: 12px;
    margin-top: 25px;
    @include rp {
      text-align: center;
      margin-top: 40/768*100vw;
    }
    @include sp {
      @include vw(24);
      margin-top: 30/750*100vw;
    }
  }
}